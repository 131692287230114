<template>
  <div>
    <app-header :routes="routes" />
      <Main>
        <a-row :gutter="15">
          <a-col :xs="24">
            <list-board :lists="filteredLists" />
          </a-col>
        </a-row>
      </Main>
  </div>
</template>

<script>
import {
  computed, defineComponent, ref
} from 'vue';
import {useStore} from 'vuex';
import {Main} from '@/components/shared/styledComponents/Main';
import ListBoard from '@/components/dynamicLists/ListBoard';

export default defineComponent({
  name: 'DynamicLists',
  components: {
    Main,
    ListBoard
  },
  setup() {
    const routes = [{
      name: 'BackOfficeDynamicLists',
      breadcrumbName: 'views.dynamicLists.title'
    }];

    const {dispatch, getters} = useStore();

    const loading = ref(false);
    const filteredLists = computed(() => getters.filteredLists);

    const getLists = async () => {
      loading.value = true;
      await dispatch('getFilteredLists');
      loading.value = false;
    };

    getLists();
    return {
      routes,
      filteredLists,
      loading
    };
  }
});
</script>
