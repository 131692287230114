<template>
  <DynamicListsWrap>
    <sdCards headless>
      <div class="sDash_dynamic-lists-board">
        <template v-for="(listItems, listName) in lists" :key="listName">
          <div class="sDash_single-dynamic-list">
            <perfect-scrollbar ref="scrollbarRef" class="always-active-scrollbar"
              :options="{wheelSpeed: 2, swipeEasing: true,suppressScrollX: true,}">
              <div class="sDash_single-dynamic-list-scrollable">

                <div class="sDash_single-dynamic-list__header">
                  <h4 class="list-header-title">
                    {{ $t(`models.dynamicList.attributes.listNames.${listName}`)}}
                  </h4>
                </div>

                <div class="sDash_dynamic-list-item">
                  <ListItem
                    v-for="item in listItems"
                    :key="listName+'_'+item._jv.id"
                    :itemId="listName+'_'+item._jv.id"
                    :itemToEdit="itemToEdit"
                    :listToEdit="listName"
                    :item="item"
                    :primaryColor="primaryColor"
                    @editListItem="editListItem"
                    @closeEditListItem="closeEditListItem"
                  />
                </div>

                <add-item-form
                  v-if="$can('manage', 'dynamicList')"
                  :listToEdit="listToEdit"
                  :listName="listName"
                  @addItem="addItem(listName)"
                  @closeAddItemForm="closeAddItemForm"
                />
              </div>
            </perfect-scrollbar>
          </div>
        </template>
      </div>
    </sdCards>
  </DynamicListsWrap>
</template>
<script>
import {DynamicListsWrap} from './style';

import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import {
  defineComponent, ref, toRefs, watch, nextTick
} from 'vue';
import VueTypes from 'vue-types';
import ListItem from './ListItem';
import AddItemForm from './AddItemForm';
import {theme} from '@/config/theme/themeVariables';
import _ from 'lodash';

export default defineComponent({
  name: 'DynamicListsBoard',
  components: {
    DynamicListsWrap,
    PerfectScrollbar,
    ListItem,
    AddItemForm
  },
  props: {
    lists: VueTypes.object
  },
  setup(props) {
    const listToEdit = ref('');
    const itemToEdit = ref('');

    const addItem = (listName) => listToEdit.value = listName;
    const closeAddItemForm = () => listToEdit.value = '';

    const editListItem = (id) => itemToEdit.value = id;
    const closeEditListItem = () => itemToEdit.value = '';

    const {lists} = toRefs(props);
    const scrollbarRef = ref();
    watch(lists, async () => {
      if (!_.isEmpty(lists.value)) {
        await nextTick();
        // Fix scrollbar not showing unless user actually scrolls
        setTimeout(() => scrollbarRef.value.update(), 1);
      }
    });

    return {
      listToEdit,
      itemToEdit,
      primaryColor: theme['primary-color'],
      scrollbarRef,
      addItem,
      closeAddItemForm,
      editListItem,
      closeEditListItem
    };
  }
});
</script>
