<template>
  <div :class="listName === listToEdit ? 'sDash_addItem-control add-item-on' :'sDash_addItem-control'">
    <a class="btn-addItem" @click="$emit('addItem')">
      <sdFeatherIcons type="plus" size="12" />
      <span>{{$t('views.dynamicLists.addItem')}}</span>
    </a>
    <div class="sDash_addItem-from">
      <a-row type="flex" justify="center" align="middle">
        <a-col :xs="21">
          <a-input
            v-model:value="value"
            class="sDash_addItem-input"
            :placeholder="$t('views.dynamicLists.enterValue')"
            @pressEnter="addListItem"
          />
        </a-col>
        <a-col :xs="3">
          <a class="float-right" @click="closeAddItemForm">
            <sdFeatherIcons type="x" size="18" />
          </a>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import {defineComponent, ref} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';

export default defineComponent({
  name: 'AddItemForm',
  emits: ['addItem', 'closeAddItemForm'],
  props: {
    listName: VueTypes.string.required,
    listToEdit: VueTypes.string.def('')
  },
  setup(props, context) {
    const value = ref('');
    const {dispatch} = useStore();
    const addListItem = async () => {
      const status = await dispatch('addDynamicListItem', {[_.snakeCase('listName')]: props.listName, value: value.value});
      if (status === 200) {
        context.emit('closeAddItemForm');
        value.value = '';
      }
    };

    const closeAddItemForm = () => {
      context.emit('closeAddItemForm');
      value.value = '';
    };

    return {
      value,
      addListItem,
      closeAddItemForm
    };
  }
});
</script>
