import Styled from 'vue3-styled-components';

const DynamicListsWrap = Styled.div`
.ant-card-body{
  min-height: 600px;
}
  h1{
    margin-bottom: 20px;
  }
  .sDash_dynamic-lists-board{
    display: flex;
    margin: -10px;
    flex-wrap: wrap;
    .sDash_single-dynamic-list{
      flex: 0 0 25%;
      padding: 10px;
      max-height: 400px;
      margin-bottom: 30px;
      @media only screen and (max-width: 1599px){
        flex: 0 0 25%;
      }
      @media only screen and (max-width: 1399px){
        flex: 0 0 33.333%;
      }
      @media only screen and (max-width: 767px){
        flex: 0 0 50%;
      }
      @media only screen and (max-width: 575px){
        flex: 0 0 100%;
      }
      .ps{
        max-height: 300px;
        height: auto;
      }
      .sDash_single-dynamic-list-scrollable{
        border-radius: 5px;
        background-color: ${({theme}) => theme['bg-color-normal']};
      }
      .sDash_addItem-control{
        padding-bottom: 20px;
        &.add-item-on{
          .sDash_addItem-from{
            display: block;
          }
          .btn-addItem{
            display: none;
          }
        }
        .sDash_addItem-from{
          padding: 0 20px 20px;
          display: none;
        }
        .sDash_addItem-input{
          padding: 10px;
          border-radius: 4px;
          background-color: #fff;
          width: 100%;
          height: 38px;
          border: 1px solid ${({theme}) => theme['border-color-normal']};
          &:focus{
            outline: none;
          }
        }
        .sDash_addItem-action{
          margin-top: 10px;
          display: flex;
          align-items: center;
          .add-column{
            ${({theme}) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
          }
          a{
            line-height: 1;
          }
          svg,
          i{
            color: ${({theme}) => theme['gray-color']};
          }
        }
      }
    }
    .sDash_single-dynamic-list__header{
      padding: 18px 20px 10px 20px;
      position: relative;
      &.editable{
        .list-header-title{
          display: none;
        }
      }
      .list-header-title{
        font-size: 15px;
        font-weight: 500;
        display:  flex;
        justify-content: space-between;
        & > span{
          display: inline-flex;
          align-items: center;
          i{
            ${({theme}) => (theme.rtl ? 'padding-left' : 'padding-right')}: 8px;
          }
        }
      }
    }
    .sDash_dynamic-list-item{
      padding: 0 20px 0 20px;
    }
    .sDash_dynamic-list-item__single{
      position: relative;
      min-height: 48px;
      padding: 12px 15px;
      background-color: #fff;
      box-shadow: 0 0 5px #9299BB10;
      border-radius: 4px;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover{
        .sDash_dynamic-list-item__title{
          .btn-edit{
            line-height: 1;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
          }
        }
      }
      .sDash_dynamic-list-item__title{
        font-size: 15px;
        font-weight: 500;
        margin: 0 5px 0 0;
        display: flex;
        justify-content: space-between;
        a{
          color: ${({theme}) => theme['gray-color']};
        }
        .end-status{
          display: flex;
          align-items: center
        }
        .btn-edit{
          display: flex;
          align-items: center;
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          svg,
          img{
            width: 14px;
            height: 14px;
            color: ${({theme}) => theme['gray-color']};
          }
        }
      }
      .sDash_dynamic-list-item__single.deactivated{
        background-color: ${({theme}) => theme['light-hover']};
      }
    }

    .btn-addItem{
      display: inline-block;
      width: calc(100% - 40px);
      padding: 8px 0;
      text-align: center;
      border-radius: 4px;
      position: relative;
      ${({theme}) => (theme.rtl ? 'right' : 'left')}: 20px;
      color: ${({theme}) => theme['gray-color']};
      background-color: ${({theme}) => theme['border-color-normal']};
      svg,
      i,
      img{
        ${({theme}) => (theme.rtl ? 'margin-left' : 'margin-right')}: 3px;
        color: ${({theme}) => theme['gray-color']};
      }
    }
  }
`;

// Create an <StyledInput> component that'll render an <input> tag with some styles
const StyledInput = Styled.input`
  font-size: 1.25em;
  padding: 0.5em;
  margin: 0.5em;
  color: palevioletred;
  background: papayawhip;
  border: none;
  border-radius: 3px;

  &:hover {
    box-shadow: inset 1px 1px 2px rgba(0,0,0,0.1);
  }
`;

// Create a <StyledTitle> Vue component that renders an <h1> which is
// centered, palevioletred and sized at 1.5em
const StyledTitle = Styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`;


export {
  DynamicListsWrap,
  StyledInput,
  StyledTitle
};
