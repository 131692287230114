<template>
  <div class="list-group-item sDash_dynamic-list-item__single">
    <div v-if="itemToEdit !== itemId">
      <h4 class="sDash_dynamic-list-item__title">
        <a-checkbox
          :disabled="!$can('manage', 'dynamicList')"
          v-model:checked="isActive"
          @change="updateListItem"
        />
        <span>{{item.value}}</span>
        <template v-if="item.listName === 'tracking_status'">
          <span class="end-status" v-if="item.endStatus" >
            <a-tooltip :title="$t('views.dynamicLists.endState')">
              <sdFeatherIcons type="check-circle" size="16" :stroke="primaryColor" />
            </a-tooltip>
          </span>
          <a-tooltip v-else-if="$can('manage', 'dynamicList')"
           :title="$t('views.dynamicLists.markAsEndState')"
          >
            <a class="btn-edit" @click="updateFinalState">
              <sdFeatherIcons type="check-circle" size="12" />
            </a>
          </a-tooltip>
        </template>
        <template v-if="$can('manage', 'dynamicList')">
          <a-tooltip :title="$t('actions.edit')">
            <a class="btn-edit pl-10" @click="$emit('editListItem', itemId)">
              <sdFeatherIcons type="edit-2" size="12" />
            </a>
          </a-tooltip>
        </template>
      </h4>
    </div>
    <div v-else-if="itemToEdit === itemId" class="sDash_addItem-from">
      <a-row type="flex" justify="center" align="middle">
        <a-col :xs="21">
          <a-input
            v-model:value="itemValue"
            class="sDash_addItem-input"
            @pressEnter="updateListItem"
          />
        </a-col>
        <a-col :xs="3">
          <a class="float-right" @click="$emit('closeEditListItem')">
            <sdFeatherIcons type="x" size="18" />
          </a>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import {ref, toRefs} from 'vue';
import {useStore} from 'vuex';
import VueTypes from 'vue-types';
import _ from 'lodash';

export default {
  name: 'ListItem',
  props: {
    itemId: VueTypes.string,
    itemToEdit: VueTypes.string,
    listToEdit: VueTypes.string,
    primaryColor: VueTypes.string,
    item: VueTypes.object.required
  },
  emits: ['editListItem', 'closeEditListItem'],
  setup(props, context) {
    const {item} = toRefs(props);
    const checked = ref();
    const itemValue = ref(item.value.value);
    const isActive = ref(item.value.active);

    const {dispatch} = useStore();
    const updateListItem = async () => {
      const status = await dispatch('updateDynamicListItem', {
        item: item.value, form: {value: itemValue.value, active: isActive.value}
      });
      if (status === 200) context.emit('closeEditListItem');
      else isActive.value = item.value.active;
    };

    const updateFinalState = async () => {
      await dispatch('updateDynamicListItem', {item: item.value, form: {[_.snakeCase('endStatus')]: true}});
    };

    return {
      itemValue,
      isActive,
      checked,
      updateListItem,
      updateFinalState
    };
  }
};
</script>

<style lang="scss" scoped>
.sDash_dynamic-list-item__title span {
  flex-grow: 1;
  text-align: center;
}
</style>
